export enum EColorsNames {
  Gray = 'gray',
  Green = 'green',
  Red = 'red',
  Yellow = 'yellow',
  Blue = 'blue',
}

export type TThemeColors = {
  [ColorProp in EColorsNames]: Record<number, `${`#` | `rgb`}${string}`>
};
