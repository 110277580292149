import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IAppConfig, IAppStore } from './store.types';

export const useAppStore = create<IAppStore>()(persist(immer((set) => ({
  config: {},
  async fetchConfig() {
    try {
      const res = await fetch(`/config/config.json`);
      const data = await res.text();
      const sanitizedData = data.replace(/(\r\n|\n|\r)/gm, "");
      const result: IAppConfig = JSON.parse(sanitizedData);

      set({ config: result });

      return result;
    } catch (error) {
      console.error(error);

      set({ config: { api: '/api' } });

      return { api: '/api' };
    }
  },
})), {
  name: 'app-config',
  storage: createJSONStorage(() => sessionStorage),
}));
