import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from 'widgets/header';
import { LoadingIndicator } from 'shared/ui/loading-indicator';
import { ModalProvider } from 'shared/ui/modal';
import { DefaultLayoutStyled } from './default.styled';

export const DefaultLayout: FC = () => {
  return (
    <DefaultLayoutStyled>
      <Header />
      <ModalProvider>
        <Suspense fallback={<LoadingIndicator type="full" />}>
          <Outlet />
        </Suspense>
      </ModalProvider>
    </DefaultLayoutStyled>
  );
};
