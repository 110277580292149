import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useGroupNavigation } from 'entities/role';
import { LogoIcon } from 'shared/ui';
import { UserState } from '../user-state';
import { HeaderStyled } from './header.styled';

export const Header: FC = () => {
  const navigationList = useGroupNavigation();

  return (
    <HeaderStyled>
      <div className="navigation">
        <div className="logo">
          <NavLink to={'/'}>
            <LogoIcon />
          </NavLink>
        </div>
        <nav>
          {navigationList.map(nav => (
            <NavLink key={nav.path} to={nav.path}>
              {nav.text}
            </NavLink>
          ))}
        </nav>
      </div>
      <UserState />
    </HeaderStyled>
  );
};
