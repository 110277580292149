import styled from 'styled-components';
import { IToastWrapperStyledProps } from './toaster-provider.types';

export const ToastWrapperStyled = styled.div<IToastWrapperStyledProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0px 4px 8px 2px #6C79931F, 0px 2px 4px 0px #6C799314;
  background-color: ${({ theme }) => theme.colors.gray[90]}CC;
  opacity: ${({ $isVisible }) => $isVisible ? 1 : 0};
  color: ${({ theme }) => theme.colors.gray[0]};
`;
