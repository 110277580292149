import { FC, useCallback, useState } from 'react';
import { Range, DateRange, RangeKeyDict } from 'react-date-range';
import { useLocalizedFns } from '../../lib/date-fns';
import { DatePickStyled } from './date-pick-styled';
import { IDatePickProps } from './date-pick.types';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './theme.css';

export const DatePick: FC<IDatePickProps> = ({ onChange, ranges = [] }) => {
  const [state, setState] = useState<Range[]>([
    {
      startDate: ranges[0],
      endDate: ranges[1],
      key: 'selection',
    },
  ]);

  const handleDateRangeChange = useCallback(
    (item: RangeKeyDict) => {
      setState([item.selection]);

      const { startDate, endDate } = item.selection;

      if (onChange && startDate && endDate) {
        onChange([startDate, endDate]);
      }
    },
    [onChange],
  );

  const fnsLocale = useLocalizedFns();

  return (
    <DatePickStyled>
      <DateRange
        onChange={handleDateRangeChange}
        showMonthAndYearPickers={false}
        showDateDisplay={false}
        moveRangeOnFirstSelection={false}
        preventSnapRefocus={true}
        months={2}
        ranges={state}
        monthDisplayFormat="LLLL y"
        weekdayDisplayFormat="EEEEEE"
        direction="horizontal"
        locale={fnsLocale}
      />
    </DatePickStyled>
  );
};
