import styled from 'styled-components';

export const ModalStyled = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 500;

  .modal-shadow {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.gray[80]}0D;
    z-index: 100;
  }

  .modal-wrapper {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    width: 720px;
    background-color: ${({ theme }) => theme.colors.gray[0]};
    z-index: 101;
  }
`;
