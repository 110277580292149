import styled, { RuleSet, css } from 'styled-components';
import { EElementSize } from '../../types';
import { IDropFooterStyledProps } from './drop-footer.types';

const SizeMap: Record<EElementSize, RuleSet> = {
  [EElementSize.L]: css`
    padding: 16px 24px;
  `,
  [EElementSize.M]: css`
    padding: 16px 16px;
  `,
  [EElementSize.S]: css`
    padding: 8px 8px;
  `,
};

export const DropFooterStyled = styled.div<IDropFooterStyledProps>`
  border-top: 1px solid ${({ theme }) => theme.colors.gray[10]};
  display: flex;
  gap: 8px;
  justify-content: flex-${({ $actionsAlign }) => $actionsAlign};

  ${({ $size }) => SizeMap[$size]};
`;
