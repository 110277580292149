import { EElementSize } from '../../types';

export enum ETagVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export type TTagSize = EElementSize.S | EElementSize.M;

export interface ITagProps extends React.HTMLAttributes<HTMLSpanElement> {
  children?: React.ReactNode;
  shouldStopClick?: boolean;
  isClearable?: boolean;
  $variant?: ETagVariant;
  $size?: TTagSize;
  onClose?: () => void;
}

export interface ITagStyledProps {
  $variant: ETagVariant;
  $size: TTagSize;
  $isClearable?: boolean;
}
