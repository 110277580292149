import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import { Logout } from 'features/user';
import { useRoleColor } from 'entities/role';
import { useUserStore } from 'entities/user';
import { LogoutIcon, Badge } from 'shared/ui';
import { UserStateStyled } from './user-state.styled';

export const UserState: FC = () => {
  const { role, name, customerName } = useUserStore(
    useShallow(state => ({
      role: state.user?.group,
      name: state.user?.name,
      customerName: state.user?.customerName,
    })),
  );
  const roleColor = useRoleColor(role);
  const [t] = useTranslation();
  const formattedRole = useMemo(
    () =>
      [role && t(`role.${role}`), customerName].filter(el => !!el).join(' '),
    [customerName, role, t],
  );

  return (
    <UserStateStyled>
      <div className="info">
        {name && <div className="username">{name}</div>}
        {role && <Badge color={roleColor}>{formattedRole}</Badge>}
      </div>
      <div className="actions">
        <Logout>
          <LogoutIcon />
        </Logout>
      </div>
    </UserStateStyled>
  );
};
