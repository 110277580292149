import styled from 'styled-components';

export const DateRangeHeaderStyled = styled.div`
  display: flex;
  gap: 24px;
  position: relative;

  &:after {
    content: "-";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  & > * {
    display: flex;
    gap: 4px;
    width: 50%;
    position: relative;
    z-index: 1;

    & > *:first-child {
      width: 160px;
      box-sizing: border-box;
    }

    & > *:last-child {
      flex-grow: 1;
    }
  }
`;
