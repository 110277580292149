import { FC, useCallback, useMemo, useState } from 'react';
import { DialogContext, IDialogOptions } from '../../lib/dialog';
import { Dialog } from './dialog';
import { IDialogProviderProps, IDialogState } from './dialog.types';

export const DialogProvider: FC<IDialogProviderProps> = ({ children }) => {
  const [dialogState, setDialogState] = useState<IDialogState>({
    isOpen: false,
    resolve: null,
    reject: null,
  });

  const [dialogPayload, setDialogPayload] = useState<IDialogOptions>({
    title: '',
  });

  const closeDialog = useCallback(() => {
    if (dialogState.reject) {
      dialogState.reject();
    }
    setDialogState({ isOpen: false, resolve: null, reject: null });
  }, [dialogState]);

  const openDialog = useCallback((payload: IDialogOptions) => {
    setDialogPayload(payload);
    return new Promise<void>((resolve, reject) => {
      setDialogState({ isOpen: true, resolve, reject });
    });
  }, []);

  const confirmDialog = useCallback(() => {
    if (dialogState.resolve) {
      dialogState.resolve();
    }
    setDialogState({ isOpen: false, resolve: null, reject: null });
  }, [dialogState]);

  const dialogContextProviderValue = useMemo(
    () => ({ closeDialog, isOpen: dialogState.isOpen, openDialog }),
    [closeDialog, dialogState.isOpen, openDialog],
  );

  return (
    <DialogContext.Provider value={dialogContextProviderValue}>
      {dialogState.isOpen && (
        <Dialog
          onClose={closeDialog}
          onConfirm={confirmDialog}
          {...dialogPayload}
        />
      )}
      {children}
    </DialogContext.Provider>
  );
};
