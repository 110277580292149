import { FC } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeContext } from 'shared/types/theme';
import { Colors } from './colors';

interface IThemeProviderProps {
  children?: React.ReactNode;
}

export const ThemeProvider: FC<IThemeProviderProps> = props => {
  const defaultTheme: ThemeContext = {
    colors: Colors,
  };

  return <StyledThemeProvider {...props} theme={defaultTheme} />;
};
