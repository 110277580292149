import styled from 'styled-components';

export const DropContentStyled = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray[10]};
  border-radius: 12px;
  box-shadow: 0px 8px 8px -4px #0A265C0A;
  box-shadow: 0px 20px 24px -4px #0A265C1A;
  background-color: ${({ theme }) => theme.colors.gray[0]};
  overflow: hidden;
`;
