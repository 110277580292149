import styled from 'styled-components';

export const HeaderStyled = styled.header`
  background-color: ${({ theme }) => theme.colors.gray[0]};
  padding: 0px 24px 0px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[10]};
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;

  .navigation {
    display: flex;
    align-items: center;
    gap: 24px;

    .logo {
      cursor: pointer;
    }

    nav {
      display: flex;
      align-items: start;

      a {
        display: flex;
        align-items: center;
        height: 60px;
        text-decoration: none;
        padding: 0 12px;
        color: ${({ theme }) => theme.colors.gray[50]};
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        box-sizing: border-box;
        border-bottom: 2px solid transparent;

        &:hover {
          border-bottom: 2px solid ${({ theme }) => theme.colors.gray[30]};
          color: ${({ theme }) => theme.colors.gray[70]};
        }

        &.active,
        &:active {
          border-bottom: 2px solid ${({ theme }) => theme.colors.gray[90]};
          color: ${({ theme }) => theme.colors.gray[90]};
        }
      }
    }
  }
`;
