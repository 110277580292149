import { RefObject } from 'react';

export const useDispatchInputEvent = (inputRef: RefObject<HTMLInputElement>) => (value: string) => {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    'value',
  )?.set;
  nativeInputValueSetter?.call(inputRef.current, value);
  const event = new Event('input', { bubbles: true });
  inputRef.current?.dispatchEvent(event);
};
