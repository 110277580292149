import styled from 'styled-components';

export const DefaultLayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 160px;

  main {
    flex-grow: 1;
    position: relative;
  }
`;
