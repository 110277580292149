import { add, set } from 'date-fns';
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMask } from 'react-imask';
import { useLocalizedFormat } from '../../lib/date-fns';
import { EElementSize } from '../../types/element';
import { Button, EButtonType } from '../button';
import { DatePick } from '../date-pick';
import { DropContent } from '../drop-content';
import { useDropDownContext } from '../drop-down/use-drop-down-context';
import { DropFooter } from '../drop-footer';
import { DropHeader } from '../drop-header';
import { MaskedInput } from '../mask-input';
import { TextField, TextFieldStyled } from '../text-field';
import { DateRangeHeaderStyled } from './date-range-content.styled';
import { IDateRangeContentProps } from './date-range-content.types';

const dateFormat = 'dd.MM.yyyy';
const timeFormat = 'HH:mm';
const initialHours = { hours: 0, minutes: 0 };
const defaultStartDate = set(new Date(), initialHours);
const defaultDateRange = [
  defaultStartDate,
  set(add(defaultStartDate, { days: 28 }), { hours: 23, minutes: 59 }),
];

const timeMaskBlocks = {
  HH: {
    mask: IMask.MaskedRange,
    from: 0,
    to: 23,
    maxLength: 2,
  },
  MM: {
    mask: IMask.MaskedRange,
    from: 0,
    to: 59,
    maxLength: 2,
  },
};

export const DateRangeContent: FC<IDateRangeContentProps> = ({
  onApply,
  ranges,
}) => {
  const { closeDropdown } = useDropDownContext();
  const { t } = useTranslation();
  const { format } = useLocalizedFormat();

  const [selectedRange, setRange] = useState<Date[]>(
    ranges.length ? ranges : defaultDateRange,
  );

  const formattedTimeRange = useMemo(
    () => selectedRange.map(date => format(date, timeFormat)),
    [selectedRange, format],
  );

  const [startTime, setStartTime] = useState(formattedTimeRange[0]);
  const [endTime, setEndTime] = useState(formattedTimeRange[1]);

  const applyHandler = useCallback(() => {
    onApply?.(selectedRange);
    closeDropdown();
  }, [selectedRange, onApply, closeDropdown]);

  const isApplyDisabled = useMemo(() => {
    return !selectedRange[0] || !selectedRange[1];
  }, [selectedRange]);

  const formattedDateRange = useMemo(
    () => selectedRange.map(date => format(date, dateFormat)),
    [selectedRange, format],
  );

  const parseTimeStringToDate = (timeString: string) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  };

  const handleStartTimeAccept = (value: string) => {
    console.log(value);
    setStartTime(value);

    if (value.length === 5 && /\d{2}:\d{2}/.test(value)) {
      const date = parseTimeStringToDate(value);
      const updatedRange = [...selectedRange];
      updatedRange[0] = set(updatedRange[0], {
        hours: date.getHours(),
        minutes: date.getMinutes(),
      });
      setRange(updatedRange);
      console.log(date);
    }
  };

  const handleEndTimeAccept = (value: string) => {
    console.log(value);
    setEndTime(value);

    if (value.length === 5 && /\d{2}:\d{2}/.test(value)) {
      const date = parseTimeStringToDate(value);
      const updatedRange = [...selectedRange];
      updatedRange[1] = set(updatedRange[1], {
        hours: date.getHours(),
        minutes: date.getMinutes(),
      });
      setRange(updatedRange);
      console.log(date);
    }
  };

  const handleDateRangeChange = (range: Date[]) => {
    const startDate = parseTimeStringToDate(startTime);
    const endDate = parseTimeStringToDate(endTime);
    const updatedRange = [...range];
    updatedRange[0] = set(updatedRange[0], {
      hours: startDate.getHours(),
      minutes: startDate.getMinutes(),
    });
    updatedRange[1] = set(updatedRange[1], {
      hours: endDate.getHours(),
      minutes: endDate.getMinutes(),
    });
    setRange(updatedRange);
  };

  const timeClickHandler = (event: MouseEvent) => {
    const input = event.target as HTMLInputElement;
    const position = input.selectionStart;

    if (position !== null) {
      input.setSelectionRange(position < 3 ? 0 : 3, position < 3 ? 2 : 5);
    }
  };

  return (
    <DropContent>
      <DropHeader>
        <DateRangeHeaderStyled>
          <div className="start-date">
            <TextField
              readOnly
              $size={EElementSize.S}
              value={formattedDateRange[0]}
              isClearable={false}
            />
            <TextFieldStyled $size={EElementSize.S}>
              <MaskedInput
                mask="HH{:}`MM"
                value={startTime}
                autofix="pad"
                lazy={false}
                placeholder="00:00"
                blocks={timeMaskBlocks}
                onAccept={handleStartTimeAccept}
                overwrite={true}
                onClick={timeClickHandler}
              />
            </TextFieldStyled>
          </div>
          <div className="end-date">
            <TextField
              readOnly
              $size={EElementSize.S}
              value={formattedDateRange[1]}
              isClearable={false}
            />
            <TextFieldStyled $size={EElementSize.S}>
              <MaskedInput
                mask="HH{:}`MM"
                value={endTime}
                autofix="pad"
                lazy={false}
                placeholder="00:00"
                blocks={timeMaskBlocks}
                onAccept={handleEndTimeAccept}
                overwrite={true}
                onClick={timeClickHandler}
              />
            </TextFieldStyled>
          </div>
        </DateRangeHeaderStyled>
      </DropHeader>
      <DatePick onChange={handleDateRangeChange} ranges={selectedRange} />
      <DropFooter>
        <Button $type={EButtonType.SECONDARY} onClick={closeDropdown}>
          {t('cancel')}
        </Button>
        <Button onClick={applyHandler} disabled={isApplyDisabled}>
          {t('apply')}
        </Button>
      </DropFooter>
    </DropContent>
  );
};
