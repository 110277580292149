import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from 'widgets/header';
import { LoadingIndicator } from 'shared/ui/loading-indicator';
import { ModalProvider } from 'shared/ui/modal';
import { MonnitoringLayoutStyled } from './monitoring.styled';

export const MonnitoringLayout: FC = () => {
  return (
    <MonnitoringLayoutStyled>
      <Header />
      <ModalProvider>
        <Suspense fallback={<LoadingIndicator type="full" />}>
          <Outlet />
        </Suspense>
      </ModalProvider>
    </MonnitoringLayoutStyled>
  );
};
