import { UserGroupEnum } from 'videoservice-openapi-react';
import { useAuthContext } from '../../user';

export const useHasRole = () => {
  const { user } = useAuthContext();

  const hasRole = (...roles: UserGroupEnum[]) => {
    return !!user.group && roles.includes(user.group);
  };

  return {
    hasRole
  };
};
