import { CSSProperties, Ref } from 'react';
import { EElementSize } from '../../types/element';

export enum EState {
  WARNING = 'warning',
  ERROR = 'error'
}

export interface ITextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  prependIcon?: React.ReactElement;
  appendIcon?: React.ReactElement;
  isClearable?: boolean;
  state?: EState;
  placeholder?: string;
  width?: string;
  innerRef?: Ref<HTMLInputElement>
  $size?: EElementSize;
  onClear?: () => void;
}

export interface IStyledTextFieldProps {
  $state?: EState;
  $size: EElementSize;
  $cursor?: CSSProperties["cursor"];
  $isStatic?: boolean;
  width?: string;
  disabled?: boolean;
}
