import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyle = createGlobalStyle`${({ theme }) => css`
  body {
    color: ${theme.colors.gray[90]};
    background-color: ${theme.colors.gray[3]};
  }

  a {
    text-decoration: none;
  }
`}`;
