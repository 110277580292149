import { FC } from 'react';
import { EDialogSize } from '../../lib/dialog';
import { Button, EButtonType } from '../button';
import { DialogStyled } from './dialog.styled';
import { IDialogProps } from './dialog.types';

export const Dialog: FC<IDialogProps> = ({
  title,
  description,
  content,
  size = EDialogSize.S,
  hasFooter = true,
  onClose,
  onConfirm,
}) => {
  return (
    <DialogStyled $size={size}>
      <div className="dialog-shadow" onClick={onClose}></div>
      <div className="dialog-wrapper">
        {(title || description) && (
          <div className="dialog-header">
            {!!title && <div className="dialog-title">{title}</div>}
            {!!description && (
              <div className="dialog-description">{description}</div>
            )}
          </div>
        )}
        {!!content && <div className="dialog-content">{content}</div>}
        {size === EDialogSize.S && hasFooter && (
          <div className="dialog-actions">
            <Button onClick={onConfirm}>Подтвердить</Button>
            <Button onClick={onClose} $type={EButtonType.SECONDARY}>
              Отменить
            </Button>
          </div>
        )}
      </div>
    </DialogStyled>
  );
};
