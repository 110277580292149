import { Configuration } from 'videoservice-openapi-react';
import { useAppStore } from '../../lib';

export const apiConfig = new Configuration({
  get basePath() {
    return import.meta.env.PROD ? useAppStore.getState().config.api : '/api';
  },
  credentials: 'include',
  middleware: [
    {
      async pre(context) {
        context.init.redirect = 'error';

        return context;
      },
    }
  ]
});
