import { FC } from 'react';
import { useLogout } from '../../lib';
import { LogoutStyled } from './logout.styled';
import { ILogoutProps } from './logout.types';

export const Logout: FC<ILogoutProps> = ({ isDisabled, children }) => {
  const { logout } = useLogout();

  return (
    <LogoutStyled onClick={logout} disabled={isDisabled}>
      {children}
    </LogoutStyled>
  );
};
