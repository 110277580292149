import styled, { RuleSet, css } from 'styled-components';
import { EElementSize } from '../../types';
import { EButtonSize, EButtonType, IStyledButtonProps } from './button.types';

const SizeMap: Record<EButtonSize, RuleSet> = {
  [EElementSize.M]: css`
    padding: 12px;

    &:has(.inner) {
      padding: 8px 16px;
    }

    &:has(.inner + svg) {
      padding-right: 12px;
    }

    &:has(svg + .inner) {
      padding-left: 12px;
    }

    font-size: 16px;
    line-height: 24px;
    height: 40px;
    gap: 8px;
  `,
  [EElementSize.S]: css`
    padding: 8px;

    &:has(.inner) {
      padding: 6px 12px;
    }

    &:has(.inner + svg) {
      padding-right: 8px;
    }

    &:has(svg + .inner) {
      padding-left: 8px;
    }

    font-size: 14px;
    line-height: 20px;
    height: 32px;
    gap: 4px;
  `,
};

const TypeMap: Record<EButtonType, RuleSet> = {
  [EButtonType.PRIMARY]: css`
    background-color: ${({ theme }) => theme.colors.blue[50]};
    color: ${({ theme }) => theme.colors.gray[0]};

    svg path {
      fill: ${({ theme }) => theme.colors.gray[0]};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.gray[5]};
    }

    &:not(:disabled):hover {
      background-color: ${({ theme }) => theme.colors.blue[60]};
    }

    &:not(:disabled):active {
      background-color: ${({ theme }) => theme.colors.blue[70]};
    }
  `,
  [EButtonType.SECONDARY]: css`
    background-color: ${({ theme }) => theme.colors.blue[5]};
    color: ${({ theme }) => theme.colors.blue[50]};
    border: 1px solid ${({ theme }) => theme.colors.blue[20]};

    svg path {
      fill: ${({ theme }) => theme.colors.blue[50]};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.gray[0]};
      border: 1px solid ${({ theme }) => theme.colors.gray[5]};
    }

    &:not(:disabled):hover {
      background-color: ${({ theme }) => theme.colors.blue[10]};
      color: ${({ theme }) => theme.colors.blue[60]};
      border: 1px solid ${({ theme }) => theme.colors.blue[30]};

      svg path {
        fill: ${({ theme }) => theme.colors.blue[60]};
      }
    }

    &:not(:disabled):active {
      background-color: ${({ theme }) => theme.colors.blue[10]};
      color: ${({ theme }) => theme.colors.blue[70]};

      svg path {
        fill: ${({ theme }) => theme.colors.blue[70]};
      }
    }
  `,
  [EButtonType.TERTIARY]: css`
    background-color: ${({ theme }) => theme.colors.blue[3]};
    color: ${({ theme }) => theme.colors.gray[50]};

    svg path {
      fill: ${({ theme }) => theme.colors.gray[50]};
    }

    &:not(:disabled):hover {
      background-color: ${({ theme }) => theme.colors.blue[5]};
      color: ${({ theme }) => theme.colors.gray[60]};

      svg path {
        fill: ${({ theme }) => theme.colors.gray[60]};
      }
    }

    &:not(:disabled):active {
      background-color: ${({ theme }) => theme.colors.blue[5]};
      color: ${({ theme }) => theme.colors.gray[70]};

      svg path {
        fill: ${({ theme }) => theme.colors.gray[70]};
      }
    }
  `,
  [EButtonType.GHOST_BLUE]: css`
    background-color: transparent;
    color: ${({ theme }) => theme.colors.blue[50]};

    svg path {
      fill: ${({ theme }) => theme.colors.blue[50]};
    }

    &:not(:disabled):hover {
      background-color: ${({ theme }) => theme.colors.blue[50]}0F;
    }

    &:not(:disabled):active {
      background-color: ${({ theme }) => theme.colors.blue[50]}1A;
    }
  `,
  [EButtonType.GHOST_GRAY]: css`
    background-color: transparent;
    color: ${({ theme }) => theme.colors.gray[50]};

    svg path {
      fill: ${({ theme }) => theme.colors.gray[50]};
    }

    &:not(:disabled):hover {
      background-color: ${({ theme }) => theme.colors.gray[50]}0F;
    }

    &:not(:disabled):active {
      background-color: ${({ theme }) => theme.colors.gray[50]}1A;
    }
  `,
};

export const ButtonStyled = styled.button<IStyledButtonProps>`
  border-radius: 12px;
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  border: none;
  font-family: inherit;
  white-space: nowrap;
  position: relative;

  svg {
    display: block;
  }

  .inner {
    flex-grow: 1;
  }

  ${({ $type }) => TypeMap[$type]}

  ${({ $size }) => SizeMap[$size]}

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.gray[30]};

    svg path {
      fill: ${({ theme }) => theme.colors.gray[30]};
    }
  }
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
