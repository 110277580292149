import { format as dateFormat } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ELanguage } from '../../types/lang';
import { locales } from './locales';

export const useLocalizedFormat = () => {
  const { i18n } = useTranslation();

  const customFormat = (date: number | Date, format: string) => dateFormat(date, format, {
    locale: locales[i18n.language as ELanguage],
  });

  return {
    format: customFormat
  };
};
