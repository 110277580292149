import styled, { css, RuleSet } from 'styled-components';
import { EElementSize } from '../../types';
import { ETagVariant, ITagStyledProps, TTagSize } from './tag.types';

const VariantStyleMap: Record<ETagVariant, RuleSet> = {
  [ETagVariant.PRIMARY]: css`
    background-color: ${({ theme }) => theme.colors.blue[10]};
  `,
  [ETagVariant.SECONDARY]: css`
    background-color: ${({ theme }) => theme.colors.blue[5]};
    border: 1px solid ${({ theme }) => theme.colors.blue[10]};
  `,
};

const SizeStyleMap: Record<TTagSize, RuleSet<ITagStyledProps>> = {
  [EElementSize.S]: css<ITagStyledProps>`
    padding: 4px ${({ $isClearable }) => $isClearable ? '4px' : '6px'} 4px 6px;
    font-size: 12px;
    line-height: 16px;
    border-radius: 8px;
    gap: 2px;
  `,
  [EElementSize.M]: css<ITagStyledProps>`
    padding: 4px ${({ $isClearable }) => $isClearable ? '8px' : '12px'} 4px 12px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 20px;
    gap: 4px;
  `,
};

export const TagStyled = styled.span<ITagStyledProps>`
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  justify-content: space-between;
  word-break: keep-all;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.gray[90]};
  box-sizing: border-box;

  .icon {
    flex-shrink: 0;
    cursor: pointer;
  }

  ${({ $variant }) => VariantStyleMap[$variant]};
  ${({ $size }) => SizeStyleMap[$size]};
`;
