import { FC } from 'react';
import { Toaster, resolveValue } from 'react-hot-toast';
import { ToastWrapperStyled } from './toaster-provider.styles';

export const ToasterProvider: FC = () => {
  return (
    <Toaster
      toastOptions={{
        duration: 2000,
        position: 'bottom-left',
      }}
    >
      {t => (
        <ToastWrapperStyled $isVisible={t.visible}>
          {t.icon}
          {resolveValue(t.message, t)}
        </ToastWrapperStyled>
      )}
    </Toaster>
  );
};
