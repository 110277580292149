import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import { useAppStore } from 'shared/lib';
import { LoadingIndicator } from 'shared/ui/loading-indicator';

interface IConfigProviderProps {
  children?: React.ReactNode;
}

export const ConfigProvider: FC<IConfigProviderProps> = ({ children }) => {
  const fetchConfig = useAppStore(state => state.fetchConfig);
  const apiUrl = useAppStore(state => state.config.api);

  const { isLoading, isError, isSuccess } = useQuery({
    queryKey: ['app-config'],
    queryFn: fetchConfig,
    enabled: true,
  });

  if (isLoading) {
    return <LoadingIndicator type="full" />;
  }

  if (isError) {
    return <div>Error loading app config</div>;
  }

  return <>{isSuccess && !!apiUrl && children}</>;
};
