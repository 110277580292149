import styled, { RuleSet, css } from 'styled-components';
import { IStyledToggleProps } from './toggle.types';

const CheckedMap: Record<number, RuleSet> = {
  1: css`
    justify-content: flex-end;
    background-color: ${({ theme }) => theme.colors.blue[50]};
    border: 1px solid ${({ theme }) => theme.colors.blue[50]};

    &:not([aria-disabled="true"]):hover {
      background-color: ${({ theme }) => theme.colors.blue[60]};
      border: 1px solid ${({ theme }) => theme.colors.blue[60]};
    }
  `,
  0: css`
    justify-content: flex-start;
    background-color: ${({ theme }) => theme.colors.blue[10]};
    border: 1px solid ${({ theme }) => theme.colors.blue[20]};

    &:not([aria-disabled="true"]):hover {
      background-color: ${({ theme }) => theme.colors.blue[20]};
    }
  `,
};

export const ToggleStyled = styled.label<IStyledToggleProps>`
  display: flex;
  align-items: center;
  width: 36px;
  height: 20px;
  box-sizing: border-box;
  padding: 1px;
  border: 1px solid ${({ theme }) => theme.colors.blue[20]};
  border-radius: 12px;
  cursor: pointer;

  &[aria-disabled="true"] {
    cursor: not-allowed;
    border: 1px solid ${({ theme }) => theme.colors.blue[5]};
    background-color: ${({ theme }) => theme.colors.blue[5]};
  }

  &:before {
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    box-shadow: 0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A;
    background-color: ${({ theme }) => theme.colors.gray[0]};
  }

  input[type='checkbox'] {
    display: none;
    visibility: hidden;
  }

  ${({ $checked }) => CheckedMap[Number($checked)]}
`;
