import {
  CustomMeta,
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { FC, useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import { useUserStore } from 'entities/user';
import { getErrorMessageFromMeta } from 'shared/lib';

interface IQueryProviderProps {
  children?: React.ReactNode;
}

export const QueryProvider: FC<IQueryProviderProps> = ({ children }) => {
  const { t } = useTranslation();
  const user = useUserStore(useShallow(state => state.user));

  const defaultMessages = useMemo(
    () => ({
      403: t('error.access denied'),
      500: t('error.server error'),
    }),
    [t],
  );

  const handleError = useCallback(
    function <V>(
      error: Error,
      metaProvider: { meta?: CustomMeta<V> },
      variables?: V,
    ) {
      const messageFromMeta = getErrorMessageFromMeta<V>(
        error,
        metaProvider?.meta,
        defaultMessages,
      );
      let message = '';

      if (typeof messageFromMeta === 'function') {
        message = messageFromMeta(variables);
      } else {
        message = messageFromMeta || '';
      }

      toast(message || t('error.unknown error'));
    },
    [defaultMessages, t],
  );

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error, query) => handleError(error, query),
    }),
    mutationCache: new MutationCache({
      onError: (error, variables, _c, mutation) =>
        handleError(error, mutation, variables),
    }),
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        enabled: !!user,
        retry: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
