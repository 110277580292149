import { FC, useEffect } from 'react';
import { useModalContext } from '../../lib/modal';
import { ModalStyled } from './modal.styled';
import { IModalProps } from './modal.types';

export const Modal: FC<IModalProps> = ({ children }) => {
  const { closeModal } = useModalContext();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  });

  return (
    <ModalStyled>
      <div className="modal-shadow" onClick={() => closeModal()}></div>
      <div className="modal-wrapper">{children}</div>
    </ModalStyled>
  );
};
