import { useContext } from 'react';
import { DropDownContext } from './drop-down.provider';

export const useDropDownContext = () => {
  const context = useContext(DropDownContext);
  if (!context) {
    throw new Error('useDropDownContext must be used within a DropDownProvider');
  }
  return context;
};
