import { EElementSize } from '../../types';

export enum EActionsAlign {
  START = 'start',
  END = 'end'
}

export interface IDropFooterProps {
  children?: React.ReactNode;
  $size?: EElementSize;
  actionsAlign?: EActionsAlign;
}

export interface IDropFooterStyledProps {
  $size: EElementSize;
  $actionsAlign: EActionsAlign;
}
