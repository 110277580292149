import styled, { RuleSet, css } from 'styled-components';
import { EElementSize } from '../../types';
import { IDropHeaderStyledProps } from './drop-header.types';

const SizeMap: Record<EElementSize, RuleSet> = {
  [EElementSize.L]: css`
    padding: 16px 24px;
  `,
  [EElementSize.M]: css`
    padding: 16px;
  `,
  [EElementSize.S]: css`
    padding: 8px;
  `,
};

export const DropHeaderStyled = styled.div<IDropHeaderStyledProps>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[10]};

  ${({ $size }) => SizeMap[$size]};
`;
