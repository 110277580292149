import { DefaultLayout, MonnitoringLayout } from 'layout';
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from 'react-router-dom';
import { SYSTEM_ROUTES } from 'shared/lib';

const router = createBrowserRouter([
  {
    Component: DefaultLayout,
    children: [
      {
        path: '/',
        loader: () => redirect('/broadcasts'),
      },
      {
        path: '/broadcasts',
        lazy: () => import('pages/broadcasts'),
      },
      {
        path: '/restrictions',
        lazy: () => import('pages/restrictions'),
      },
      {
        path: '/providers',
        lazy: () => import('pages/providers'),
      },
      {
        path: '/secrets',
        lazy: () => import('pages/secrets'),
      },
      {
        path: '/synonyms',
        lazy: () => import('pages/synonyms'),
      },
      {
        path: `/${SYSTEM_ROUTES.FORBIDDEN}`,
        lazy: () => import('pages/403'),
      },
      {
        path: '*',
        lazy: () => import('pages/404'),
      },
    ],
  },
  {
    Component: MonnitoringLayout,
    children: [
      {
        path: '/monitoring',
        lazy: () => import('pages/monitoring'),
      },
    ],
  },
]);

export function BrowserRouter() {
  return <RouterProvider router={router} />;
}
