import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { ELanguage, LanguageList } from 'shared/types/lang';

const options: InitOptions = {
  fallbackLng: ELanguage.RU,
  supportedLngs: LanguageList,
  load: 'languageOnly',
  detection: {
    caches: ['cookie', 'localStorage'],
  },
};

i18n.use(Backend).use(initReactI18next).use(LanguageDetector);

if (!i18n.isInitialized) {
  i18n.init(options);
}

export { i18n };
