import { FC } from 'react';
import { EElementSize } from '../../types';
import { DropHeaderStyled } from './drop-header.styled';
import { IDropHeaderProps } from './drop-header.types';

export const DropHeader: FC<IDropHeaderProps> = ({
  children,
  $size = EElementSize.M,
}) => {
  return <DropHeaderStyled $size={$size}>{children}</DropHeaderStyled>;
};
