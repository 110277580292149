import { FC } from 'react';
import { BadgeStyled } from './badge.styled';
import { IBadgeProps } from './badge.types';

export const Badge: FC<IBadgeProps> = ({ color, isBordered, children }) => {
  return (
    <BadgeStyled $color={color} $isBordered={isBordered}>
      {children}
    </BadgeStyled>
  );
};
