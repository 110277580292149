import styled, { RuleSet, css } from 'styled-components';
import { EDialogSize } from '../../lib/dialog';
import { IDialogStyledProps } from './dialog.types';

const SizeStyleMap: Record<EDialogSize, RuleSet> = {
  [EDialogSize.S]: css`
    .dialog-wrapper {
      width: 400px;
      box-sizing: border-box;
    }
  `,
  [EDialogSize.L]: css`
    align-items: center;

    .dialog-wrapper {
      max-width: 85%;
      max-height: 80%;
      width: max-content;
      margin-top: 0;
    }
  `,
}

export const DialogStyled = styled.div<IDialogStyledProps>`
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 600;

  .dialog-shadow {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.gray[80]}0D;
    z-index: 100;
  }

  .dialog-wrapper {
    background-color: ${({ theme }) => theme.colors.gray[0]};
    z-index: 101;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0px 8px 8px -4px #0A265C0A, 0px 20px 24px -4px #0A265C1A;
    margin-top: 25vh;
    height: fit-content;
  }

  .dialog-header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .dialog-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.gray[90]};
    }

    .dialog-description {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: ${({ theme }) => theme.colors.gray[50]};
    }
  }

  .dialog-content {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  .dialog-actions {
    margin-top: 32px;
    display: flex;
    gap: 8px;

    & > * {
      width: 100%;
    }
  }

  ${({ $size }) => SizeStyleMap[$size]}
`;
