import { TThemeColors } from 'shared/types/theme';

export const Colors: TThemeColors = {
  gray: {
    0: "#FFFFFF",
    3: '#F6F7F9',
    5: '#F0F2F4',
    10: '#E2E4E9',
    20: '#C4C9D4',
    30: '#A7AFBE',
    40: '#8A94A8',
    50: '#6C7993',
    60: '#576175',
    70: '#414958',
    80: '#282F3D',
    90: '#16181D',
  },
  green: {
    3: '#F7FCF9',
    5: '#ECF9F1',
    10: '#D9F2E2',
    20: '#C2EBD2',
    30: '#94DBB5',
    40: '#6ACD9C',
    50: '#2EB877',
    60: '#039855',
    70: '#027A48',
    80: '#05603A',
    90: '#054F31',
  },
  red: {
    3: '#FDF3F2',
    5: '#FBEBE9',
    10: '#F7D7D4',
    20: '#F0AEA8',
    30: '#E8867D',
    40: '#DE5145',
    50: '#D93526',
    60: '#BF2F22',
    70: '#98251B',
    80: '#791E15',
    90: '#2B0B08',
  },
  yellow: {
    3: '#FFFCF5',
    5: '#FFFAEB',
    10: '#FEF0C7',
    20: '#FEDF89',
    30: '#FEC84B',
    40: '#FDB022',
    50: '#F79009',
    60: '#DC6803',
    70: '#B54708',
    80: '#93370D',
    90: '#7A2E0E',
  },
  blue: {
    3: '#F2F6FD',
    5: '#E9EFFB',
    10: '#D4E0F7',
    20: '#A8C0F0',
    30: '#7DA1E8',
    40: '#5281E0',
    50: '#2662D9',
    60: '#1F4EAD',
    70: '#173B82',
    80: '#0F2757',
    90: '#08142B',
  }
};
