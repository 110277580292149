import { useAppStore } from 'shared/lib';

export const useLogout = () => {
  const apiUrl = useAppStore(state => state.config.api)

  const logout = () => {
    if (apiUrl) {
      location.href = `${apiUrl}/logout`;
    }
  };

  return {
    logout,
  };
};
