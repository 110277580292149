import styled, { css } from 'styled-components';
import { IStyledBadgeProps } from './badge.types';

export const BadgeStyled = styled.label<IStyledBadgeProps>`
  padding: 4px 8px 4px 8px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border: 1px solid transparent;

  ${({ theme, $color, $isBordered }) => css`
    background-color: ${theme.colors[$color][5]};
    color: ${theme.colors[$color][$isBordered ? 40 : 30]};
    border-color: ${$isBordered ? theme.colors[$color][20] : 'transparent'};
  `}
`;
