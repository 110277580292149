import styled from 'styled-components';

export const UserStateStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .info {
    display: flex;
    align-items: center;
    gap: 8px;

    .username {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.gray[50]};
    }
  }
`;
