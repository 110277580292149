import styled, { RuleSet, css } from 'styled-components';
import { EElementSize } from '../../types/element';
import { EState, IStyledTextFieldProps } from './text-field.types';

const StateMap: Record<EState, RuleSet> = {
  [EState.ERROR]: css`
    border-color: ${({ theme }) => theme.colors.red[50]};

    .status-icon path {
      fill: ${({ theme }) => theme.colors.red[50]};
    }
  `,
  [EState.WARNING]: css`
    border-color: ${({ theme }) => theme.colors.yellow[40]};

    .status-icon path {
      fill: ${({ theme }) => theme.colors.yellow[40]};
    }
  `
};

const SizeMap: Record<EElementSize, RuleSet> = {
  [EElementSize.L]: css`
    padding: 0 16px;
    height: 48px;

    input, select {
      padding: 14px 0;
    }
  `,
  [EElementSize.M]: css`
    padding: 0 12px;
    height: 40px;

    input, select {
      padding: 10px 0;
    }
  `,
  [EElementSize.S]: css`
    padding: 0 12px;
    height: 32px;

    input, select {
      padding: 6px 0;
    }
  `,
};

export const TextFieldStyled = styled.div<IStyledTextFieldProps>`
  background-color: ${({ theme }) => theme.colors.gray[3]};
  border: 1px solid ${({ theme }) => theme.colors.gray[10]};
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  box-sizing: border-box;
  cursor: ${({ $cursor }) => $cursor || 'text'};

  width: ${({ width }) => width };

  ${({ $isStatic, disabled }) => !$isStatic && !disabled && css`
    &:hover {
      border-color: ${({ theme }) => theme.colors.gray[40]};
    }

    &:focus-within {
      border-color: ${({ theme }) => theme.colors.gray[90]};
    }
  `}

  ${({ $state }) => $state && StateMap[$state]}

  ${({ $size }) => SizeMap[$size]}

  .clear-icon {
    cursor: pointer;
  }

  input, select {
    border: none;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.gray[90]};
    font-size: 14px;
    font-weight: 500;
    flex-grow: 1;
    width: 0;
    font-family: inherit;
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.gray[30]};
    }
  }

  ${({ disabled }) => disabled && css`
    color: ${({ theme }) => theme.colors.gray[20]};
    border: 1px solid ${({ theme }) => theme.colors.gray[3]};
    cursor: not-allowed;

    input, select {
      cursor: not-allowed;

      &::placeholder {
        color: ${({ theme }) => theme.colors.gray[20]};
      }
    }
  `}
`;
