import { AuthProvider } from './auth-provider';
import { ConfigProvider } from './config-provider';
import { DialogProvider } from './dialog-provider';
import { QueryProvider } from './query-provider';
import { BrowserRouter } from './router-provider';
import { GlobalStyle, ThemeProvider } from './theme-provider';
import { ToasterProvider } from './toaster-provider/toaster-provider';

export function Providers() {
  return (
    <QueryProvider>
      <ConfigProvider>
        <AuthProvider>
          <ThemeProvider>
            <DialogProvider>
              <GlobalStyle />
              <ToasterProvider />
              <BrowserRouter />
            </DialogProvider>
          </ThemeProvider>
        </AuthProvider>
      </ConfigProvider>
    </QueryProvider>
  );
}
