import { FC } from 'react';
import { useAuthCheck, useUserStore } from 'entities/user';
import { AuthContext } from 'shared/lib/user';
import { LoadingIndicator } from 'shared/ui/loading-indicator';
import { IAuthProviderProps } from './auth-provider.types';

export const AuthProvider: FC<IAuthProviderProps> = ({ children }) => {
  const user = useUserStore(state => state.user);
  const { isLoading, isError } = useAuthCheck();

  if (isLoading) {
    return <LoadingIndicator type="full" />;
  }

  if (isError && import.meta.env.DEV) {
    return <div>Please inject credentials for dev auth</div>;
  }

  return (
    <>
      {!!user && (
        <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
      )}
    </>
  );
};
