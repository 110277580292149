import { FC } from 'react';
import { EElementSize } from '../../types';
import { LoadingIndicator } from '../loading-indicator';
import { ButtonStyled, LoadingWrapper } from './button.styled';
import { EButtonType, IButtonProps } from './button.types';

export const Button: FC<IButtonProps> = ({
  children,
  appendIcon,
  prependIcon,
  isLoading,
  $size = EElementSize.M,
  $type = EButtonType.PRIMARY,
  disabled,
  ...props
}) => {
  return (
    <ButtonStyled
      $size={$size}
      $type={$type}
      disabled={disabled || isLoading}
      {...props}
    >
      {prependIcon}
      {children && (
        <div className="inner">
          {isLoading ? (
            <LoadingWrapper>
              <LoadingIndicator $small />
            </LoadingWrapper>
          ) : (
            <></>
          )}
          {children}
        </div>
      )}
      {appendIcon}
    </ButtonStyled>
  );
};
