import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ModalContext } from '../../lib/modal';
import { Modal } from './modal';
import { IModalProviderProps } from './modal.types';

export const ModalProvider: FC<IModalProviderProps> = ({ children }) => {
  const [content, setContent] = useState<React.ReactElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setContent(null);
  }, []);

  const openModal = useCallback((content: React.ReactElement) => {
    setContent(content);
    setIsOpen(true);
  }, []);

  useEffect(() => {
    closeModal();
  }, [closeModal, location]);

  const modalContextProviderValue = useMemo(
    () => ({ closeModal, openModal, isOpen }),
    [closeModal, isOpen, openModal],
  );

  return (
    <ModalContext.Provider value={modalContextProviderValue}>
      {isOpen && <Modal>{content}</Modal>}
      {children}
    </ModalContext.Provider>
  );
};
