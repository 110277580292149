import styled from 'styled-components';

export const LogoutStyled = styled.button`
width: 32px;
height: 32px;
padding: 4px;
border: none;
background-color: transparent;
display: inline-block;
border-radius: 8px;
cursor: pointer;

&:not(:disabled):hover {
  background-color: ${({ theme }) => theme.colors.gray[3]};
}

&:not(:disabled):active {
  background-color: ${({ theme }) => theme.colors.gray[5]};
}

&:disabled {
  cursor: not-allowed;

  svg path {
    fill: ${({ theme }) => theme.colors.gray[20]};
  }
}

svg {
  display: block;
}
`;
