import { useEffect } from 'react';
import { useAppStore } from 'shared/lib';
import { useWhoami } from '../use-whoami';

export const useAuthCheck = () => {
  const { userQuery } = useWhoami();
  const apiUrl = useAppStore(state => state.config.api);

  useEffect(() => {
    if (userQuery.isError && !import.meta.env.DEV) {
      window.location.href = `${apiUrl}/ui`;
    }
  }, [userQuery.isError, apiUrl]);

  return userQuery;
};
