import { CustomMeta } from '@tanstack/react-query';

export const getErrorMessageFromMeta = <V>(error: Error, meta: CustomMeta<V> | undefined, defaultMessages: Record<number, string>) => {
  if (
    error instanceof Response
  ) {
    const messages = { ...defaultMessages, ...(meta?.errorMessages || {}) };

    return messages[error.status];
  }
};
