import { FC, MouseEvent, useCallback, useRef } from 'react';
import { useDispatchInputEvent } from '../../lib';
import { EElementSize } from '../../types/element';
import { AlertCircleIcon, CloseIcon } from '../icons';
import { TextFieldStyled } from './text-field.styled';
import { ITextFieldProps } from './text-field.types';

export const TextField: FC<ITextFieldProps> = ({
  appendIcon,
  prependIcon,
  isClearable = true,
  state,
  placeholder,
  $size = EElementSize.L,
  width,
  value,
  disabled,
  type = 'text',
  onClear,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatchInputEvent = useDispatchInputEvent(inputRef);

  const clickHandler = useCallback(() => inputRef.current?.focus(), [inputRef]);
  const clearHandler = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();

      if (typeof onClear === 'function') {
        onClear();
      }

      dispatchInputEvent('');
      inputRef.current?.focus();
    },
    [dispatchInputEvent, onClear],
  );

  return (
    <TextFieldStyled
      $size={$size}
      $state={state}
      width={width}
      onClick={clickHandler}
      disabled={disabled}
    >
      {prependIcon}
      <input
        {...props}
        value={value}
        type={type}
        ref={inputRef}
        placeholder={placeholder}
        disabled={disabled}
      />
      {state && <AlertCircleIcon className="status-icon" />}
      {appendIcon}
      {isClearable && value && (
        <CloseIcon className="clear-icon" onClick={clearHandler} />
      )}
    </TextFieldStyled>
  );
};
