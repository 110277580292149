import { FC } from 'react';
import { EElementSize } from '../../types';
import { DropFooterStyled } from './drop-footer.styled';
import { EActionsAlign, IDropFooterProps } from './drop-footer.types';

export const DropFooter: FC<IDropFooterProps> = ({
  children,
  actionsAlign = EActionsAlign.END,
  $size = EElementSize.M,
}) => {
  return (
    <DropFooterStyled $actionsAlign={actionsAlign} $size={$size}>
      {children}
    </DropFooterStyled>
  );
};
